<template>
  <mex-dialog :showDialog="showNoteDialog" dialogColor="note" dialogTitle="New Note" width="50%">
    <template v-slot:dialog-content>
      <v-overlay v-if="noteIsSaving">
        <mex-sperm-spinner spinnerText="Saving note" />
      </v-overlay>
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-model="selectedOrganization"
            :items="organizationNames"
            color="primaryAccent"
            dense
            hide-details
            label="Organization"
            outlined
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="selectedClinic"
            :items="localClinicNames"
            :readonly="!selectedOrganization"
            color="primaryAccent"
            dense
            hide-details
            label="Clinic"
            outlined
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-model="selectedLocation"
            :items="localLocationNames"
            :readonly="!selectedClinic"
            color="primaryAccent"
            dense
            hide-details
            label="Location"
            outlined
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="selectedSystemUsers"
            :items="localSystemUserNames"
            color="secondaryAccent"
            dense
            filled
            hide-details
            label="System-Users"
            multiple
            outlined
            readonly
          >
            <template v-slot:selection="data">
              <v-chip v-if="data.index < 5" class="ma-1" dense>
                <v-icon class="mr-1" x-small>mdi-account</v-icon>
                {{ data.item.text }}
              </v-chip>
              <span v-if="data.index === 5" class="grey--text caption"> (+{{ selectedSystemUsers.length - 5 }}) </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="align-stretch" cols="12">
          <v-textarea v-model="noteContent" dense label="Note" outlined rows="6"></v-textarea>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col align-self="center" cols="auto">
          <v-checkbox v-model="sendNotification" label="Send notification" />
        </v-col>
        <v-col align-self="center" cols="auto">
          <v-select
            v-model="selectedNotificationType"
            :items="notificationTypes"
            :readonly="!sendNotification"
            dense
            filled
            hide-details
            label="Notification Type"
            outlined
          >
            <template v-slot:selection="data">
              <v-icon v-if="data.item.text === 'Success'" class="mr-1" color="success">mdi-check</v-icon>
              <v-icon v-if="data.item.text === 'Warning'" class="mr-1" color="warning">mdi-alert</v-icon>
              <v-icon v-if="data.item.text === 'Error'" class="mr-1" color="error">mdi-alert-circle-outline</v-icon>
              <v-icon v-if="data.item.text === 'Info'" class="mr-1" color="info">mdi-information</v-icon>
              {{ data.item.text }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </template>
    <template slot="dialog-actions">
      <v-row justify="center">
        <v-col cols="auto">
          <mex-btn content="Add Note" icon="mdi-plus" @click="setNote" />
        </v-col>
        <v-col cols="auto">
          <mex-btn content="Minimize" icon="mdi-window-minimize" @click="$emit('minimize')" />
        </v-col>
        <v-col cols="auto">
          <mex-btn content="Discard" icon="mdi-delete" @click="discradNoteDialog" />
        </v-col>
      </v-row>
    </template>
  </mex-dialog>
</template>

<script>
import ClinicsService from '../../services/clinics.service';
import OrganizationsService from '../../services/organizations.service';
import SystemUsersService from '../../services/systemUsers.service';
import LocationsService from '../../services/locations.service';
import NotificationTypesService from '../../services/notificationTypes.service';
import NotesService from '../../services/notes.service';
import MexSpermSpinner from '../MedITEX_Vue_Components/MexComponents/MexSpermSpinner.vue';

export default {
  name: 'NoteDialog',
  components: { MexSpermSpinner },
  props: {
    showNoteDialog: {
      type: Boolean,
      default: false,
    },
    preSelection: {
      type: Object,
      default: () => {
        return {
          organization: null,
          clinic: null,
          location: null,
          systemUsers: null,
          note: null,
        };
      },
    },
    organizationNames: {
      type: Array,
      default: () => {
        return [];
      },
      description: '',
    },
    clinicNames: {
      type: Array,
      default: () => {
        return [];
      },
      description: '',
    },
    locationNames: {
      type: Array,
      default: () => {
        return [];
      },
      description: '',
    },
    systemUserNames: {
      type: Array,
      default: () => {
        return [];
      },
      description: '',
    },
  },
  data() {
    return {
      // notification
      sendNotification: true,
      selectedNotificationType: 4,
      noteContent: '',
      // selection data
      selectedOrganization: null,
      selectedClinic: null,
      selectedLocation: null,
      selectedSystemUsers: [],
      notificationTypes: [],
      // visualization
      noteIsSaving: false,

      localClinicNames: this.clinicNames,
      localSystemUserNames : this.systemUserNames,
      localLocationNames : this.locationNames,
    };
  },
  mounted() {
    this.fetchOrganizationNames();
    this.fetchNotificationTypes();
  },
  watch: {
    selectedOrganization: {
      handler() {
        this.fetchClinicNames();
        this.fetchSystemUserNames();
      },
    },
    selectedClinic: {
      handler() {
        this.fetchLocationNames();
      },
    },
  },
  methods: {
    fetchOrganizationNames() {
      if (this.organizationNames.length === 0) {
        OrganizationsService.getOrganizationNames()
          .then((organizationResponse) => {
            organizationResponse.data.forEach((orga) => {
              this.organizationNames.push({
                value: orga.OrganizationID,
                text: orga.name,
              });
            });
            if (this.preSelection.organization) {
              this.selectedOrganization = this.preSelection.organization;
              this.preSelection.organization = null;
            } else {
              this.selectedOrganization = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchClinicNames() {
      this.localClinicNames = [];
      ClinicsService.getClinicNames(this.selectedOrganization)
        .then((clinicResponse) => {
          clinicResponse.data.forEach((clinic) => {
            this.localClinicNames.push({
              value: clinic.ClinicID,
              text: clinic.name,
            });
          });
          if (this.preSelection.clinic) {
            this.selectedClinic = this.preSelection.clinic;
            this.preSelection.clinic = null;
          } else {
            this.selectedClinic = null;
          }
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
    fetchLocationNames() {
      this.localLocationNames = [];
      if (this.selectedClinic) {
        LocationsService.getLocationNames(this.selectedClinic)
          .then((locationResponse) => {
            locationResponse.data.forEach((location) => {
              this.localLocationNames.push({
                value: location.LocationID,
                text: location.name,
              });
            });
            if (this.preSelection.location) {
              this.selectedLocation = this.preSelection.location;
              this.preSelection.location = null;
            } else {
              this.selectedLocation = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchSystemUserNames() {
      this.localSystemUserNames = [];
      if (this.selectedOrganization) {
        SystemUsersService.getSystemUserNames()
          .then((systemUserResponse) => {
            systemUserResponse.data.forEach((systemUser) => {
              this.localSystemUserNames.push({
                value: systemUser.SystemUserID,
                text: systemUser.username,
              });
            });
            SystemUsersService.getSystemUsersByOrganization(this.selectedOrganization).then((systemUserOrgaResponse) => {
              this.selectedSystemUsers = [];
              systemUserOrgaResponse.data.forEach((systemUser) => {
                this.selectedSystemUsers.push(systemUser.SystemUserID);
              });
            });
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchNotificationTypes() {
      NotificationTypesService.getNotificationTypes().then((notificationTypesResponse) => {
        notificationTypesResponse.data.forEach((type) => {
          this.notificationTypes.push({
            value: type.NotificationTypeID,
            text: type.name,
          });
        });
      });
    },
    discradNoteDialog() {
      this.selectedNotificationType = null;
      this.noteContent = '';
      this.$emit('reject');
    },
    setNote() {
      this.noteIsSaving = true;
      NotesService.setNote(this.noteContent, this.selectedNotificationType, this.selectedOrganization, this.selectedClinic, this.selectedLocation)
        .then(() => {
          this.noteIsSaving = false;
          this.$emit('accept');
          this.$toast.success('Note successfully saved');
        })
        .catch((err) => {
          this.noteIsSaving = false;
          this.$taost.error(err);
        });
    },
  },
};
</script>

<style></style>
